import React from 'react';
import Main from '../../containers/Layout';
import propertyManagerLongPageContent from '../../../content/pages/marketing/property-manager-long-term.yml';
import PropertyManagerLongTermContainer from '@/containers/PropertyManagerLongTerm';

export interface PropertyManagerLongPageContent {
  viewport1: {
    tickPlates: {
      item: string;
    }[];
    title1: string;
    title2: string;
    title3: string;
    description1: string;
    description2: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
  viewport2: {
    label1: string;
    benefitCards: {
      label: string;
      text: string;
      image: string;
    }[];
    label2: string;
    useCaseCards: {
      image: string;
      label: string;
      text: string;
    }[];
  };
  viewport3: {
    label: string;
    title1: string;
    title2: string;
    boldDescription: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport4: {
    label: string;
    description: string;
    payByLinkCards: {
      count: string;
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport5: {
    title1: string;
    title2: string;
    boldDescription1: string;
    boldDescription2: string;
    boldDescription3: string;
    description1: string;
    description2: string;
    appStoreButton: {
      url: string;
    };
    googlePlayButton: {
      url: string;
    };
  };
  viewport6: {
    title: string;
    howItWorksCards: {
      title: string;
      text: string;
      image: string;
    }[];
  };
  viewport7: {
    title: string;
    description: string;
    howToCards: {
      count: string;
      text: string;
      image: string;
    }[];
  };
  viewport8: {
    bannerLabel: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
}
const PropertyManagerLongTerm: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
  } = propertyManagerLongPageContent as unknown as PropertyManagerLongPageContent;
  return (
    <Main>
      <PropertyManagerLongTermContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
          viewport8,
        }}
      />
    </Main>
  );
};

export default PropertyManagerLongTerm;
