import React from 'react';
import styled from 'styled-components';
import FirstViewport from '@/components/FirstViewport';
import {
  Body4,
  Description,
  H1,
  H3,
  P,
  SecondaryP,
  StrongInitial,
} from '@/components/Typography';
import BlueTickIcon from '../assets/icons/blueTick.svg';
import Vp1Image from '../assets/img/propertyManagerLongVp1.png';
import Vp3Image from '../assets/img/accountantsVp5.png';
import Vp5Image from '../assets/img/wholesalersVp5.png';
import CarouselCard from '@/components/CarouselCard';
import Section from '@/components/Section';
import PayByLinkCard from '@/components/PayByLinkCard';
import HowToCard from '@/components/HowToCard';
import Banner from '@/components/Banner';
import bannerBg from '../assets/img/accountantsBanner.png';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import GooglePlayBg from '../assets/icons/googlePlayBg.svg';
import AppleStoreBg from '../assets/icons/appStoreBg.svg';
import HowItWorksCard from '@/components/HowItWorksCard';
import UseCaseCard from '@/components/UseCaseCard';
import { PropertyManagerLongPageContent } from '@/pages/marketing/property-manager-long-term';

const FirstViewportWrapper = styled.div`
  background: linear-gradient(123.46deg, #fbfbfd 45.14%, #e7edf3 100.49%);
`;

const TickPlatesWrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const TickPlate = styled.div`
  display: flex;
  align-items: center;
  background-color: #e7edf3;
  border-radius: 31px;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`;

const BlueTick = styled(BlueTickIcon)`
  margin-right: 0.5rem;
`;

const VpWrapper = styled.div`
  background: linear-gradient(
    184deg,
    rgba(244, 246, 249, 1) 0%,
    rgba(251, 252, 253, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const DescriptionWrapper = styled.div`
  margin: 2rem 0;
`;

const RedSpan = styled.span`
  text-transform: uppercase;
  color: #ef6355;
`;

const Buttons = styled.div`
  margin-top: 1rem;
`;

const ViewportsWrapper = styled.div`
  background-color: #fff;
`;

const FifthViewport = styled(FirstViewport)``;

const SecondViewport = styled(Section)`
  background-color: #ffff;
  padding-bottom: 5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const UseCaseCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const UseCasesLabel = styled(Label)`
  margin-top: 3rem;
`;

const ThirdViewport = styled(FirstViewport)`
  background-color: #fff;
  padding: 0 0;
`;

const HowItWorksCardWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const FourthViewport = styled(Section)`
  background-color: #fff;
`;

const VpDescription = styled(P)`
  text-align: center;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const PayByLinkCardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const SixthViewport = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
`;

const SeventhViewport = styled(Section)`
  background-color: #fff;
`;

const CenterH1 = styled(H1)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const HowToCardsWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const EighthViewport = styled(Section)`
  background-color: #fff;
`;

const VpWrapperWhite = styled.div`
  background-color: #fff;
`;

interface PropertyManagerLongProps {
  content: PropertyManagerLongPageContent;
}

const PropertyManagerLongTermContainer: React.FunctionComponent<
  PropertyManagerLongProps
> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
  },
}) => (
  <>
    <FirstViewportWrapper>
      <FirstViewport withRightPadding img={Vp1Image}>
        <TickPlatesWrapper>
          {viewport1.tickPlates.map((el) => (
            <TickPlate key={el.item}>
              <BlueTick />
              <Body4>{el.item}</Body4>
            </TickPlate>
          ))}
        </TickPlatesWrapper>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
          {` `}
          {viewport1.title3}
        </H1>
        <StyledStrong>
          {viewport1.description1}
          {` `}
          <RedSpan> {viewport1.description2}</RedSpan>
        </StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport1.descriptionItems} />
        </DescriptionWrapper>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstViewportWrapper>
    <ViewportsWrapper>
      <SecondViewport>
        <Label>{viewport2.label1}</Label>
        <CardWrapper>
          {viewport2.benefitCards.map((item) => (
            <CarouselCard
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
        <UseCasesLabel>{viewport2.label2}</UseCasesLabel>
        <UseCaseCardWrapper>
          {viewport2.useCaseCards.map((item) => (
            <UseCaseCard
              margin="0 2rem 2rem 0"
              key={item.label}
              label={item.label}
              text={item.text}
              image={item.image}
            />
          ))}
        </UseCaseCardWrapper>
      </SecondViewport>
      <ThirdViewport
        padding="0 calc((100vw - 1200px) / 2)"
        imgWidth="50%"
        withRightPadding
        img={Vp3Image}
      >
        <SecondaryP className="accent-text-gray">{viewport3.label}</SecondaryP>
        <H1>
          {viewport3.title1}
          {` `}
          <span className="accent-text">{viewport3.title2}</span>
        </H1>
        <StyledStrong>{viewport3.boldDescription}</StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport3.descriptionItems} />
        </DescriptionWrapper>
      </ThirdViewport>
    </ViewportsWrapper>

    <FourthViewport>
      <Label>{viewport4.label}</Label>
      <VpDescription>{viewport4.description}</VpDescription>
      <PayByLinkCardsWrap>
        {viewport4.payByLinkCards.map((item) => (
          <PayByLinkCard
            key={item.label}
            number={item.count}
            label={item.label}
            text={item.text}
            imageSrc={item.image}
          />
        ))}
      </PayByLinkCardsWrap>
    </FourthViewport>
    <VpWrapper>
      <FifthViewport imgWidth="50%" withRightPadding img={Vp5Image}>
        <H1>
          <span className="accent-text">{viewport5.title1}</span>
          {` `}
          {viewport5.title2}
        </H1>
        <StyledStrong>
          {viewport5.boldDescription1}
          {` `}
          <RedSpan>{viewport5.boldDescription2}</RedSpan>
          {` `}
          {viewport5.boldDescription3}
        </StyledStrong>
        <Description>{viewport5.description1}</Description>
        <Description>{viewport5.description2}</Description>
        <Buttons>
          <ButtonDefault withImage to={viewport5.googlePlayButton.url}>
            <GooglePlayBg />
          </ButtonDefault>
          {` `}
          <ButtonDefault withImage to={viewport5.appStoreButton.url}>
            <AppleStoreBg />
          </ButtonDefault>
        </Buttons>
      </FifthViewport>

      <SixthViewport>
        <Label>{viewport6.title}</Label>
        <HowItWorksCardWrap>
          {viewport6.howItWorksCards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.text}
              image={item.image}
            />
          ))}
        </HowItWorksCardWrap>
      </SixthViewport>
    </VpWrapper>
    <VpWrapperWhite>
      <SeventhViewport>
        <CenterH1>{viewport7.title}</CenterH1>
        <VpDescription>{viewport7.description}</VpDescription>
        <HowToCardsWrap>
          {viewport7.howToCards.map((item) => (
            <HowToCard
              key={item.count}
              number={item.count}
              text={item.text}
              imageSrc={item.image}
            />
          ))}
        </HowToCardsWrap>
      </SeventhViewport>
      <EighthViewport>
        <Banner
          bgSrc={bannerBg}
          label={viewport8.bannerLabel}
          descriptionItems={viewport8.descriptionItems}
          buttonUrl={viewport8.button.url}
          buttonLabel={viewport8.button.label}
        />
      </EighthViewport>
    </VpWrapperWhite>
  </>
);

export default PropertyManagerLongTermContainer;
